import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'

const Menu = styled.ul`
    padding: 0;
    text-align: right;
    margin: 0;
    @media screen and (max-width: 768px){
        text-align: left;
        margin: 1rem 0 0;
    }
    li{
        display: inline-block;
        margin: 0 1rem;
        a{
            font-family: 'Recoleta';
            font-weight: 600;
            color: #212121;
            &:hover{
                text-decoration: none;
                color: #a8ee56;
            }
        }
        &:first-child{
            margin-left: 0;
        }
        &:last-child{
            margin-right: 0;
        }
    }
`
 
const Nav = ({data}) => (
    <Menu>
      {data.prismicMenu.data.nav.map(item => (
        <li><a href={item.primary.link.url} target="_blank">{item.primary.label.text}</a></li>
      ))}
    </Menu>
)

export default props => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        prismicMenu {
          id
          data{
            nav{
              slice_type
              primary{
                label{
                  text
                }
                link{
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Nav data={data} {...props} />}
  />
)
