import React, { Component } from "react"
import get from 'lodash/get'
import styled from 'styled-components'
import "../styles/main.scss"
import Nav from "../hooks/nav"
import { useStaticQuery, graphql } from "gatsby";

const Header = styled.header`
    text-align: left;
    .inner{
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
`

const Backlink = styled.a`
    text-align: center;
    display: block;
    padding: 1.5rem 0;
    font-family: 'Recoleta';
    font-weight: 600;
    color:#fff;
    background-color:#222;
    &:hover{
        text-decoration: none;
        color:#a8ee56;
        background-color:#fbf8f8;
    }
`

const Logo = styled.a`
    font-family: 'Recoleta';
    font-weight: 600;
    color:#212121;
    text-decoration: none;
    &:hover{
        text-decoration: none;
        color: #a8ee56;
    }
`

class ShowHeader extends Component{
    helloWorld(){

    }
    render(){
        const notHome = get(this, 'props.notHome')
        return (
            <Header className="container-fluid header">
                <div className="container">
                    <div className="col-md-10 mx-auto" style={{padding: 0}}>
                        <div className="inner row justify-content-between">
                            <div className="col-md-3">
                                <Logo href="/">hanif dawjee</Logo>
                            </div>
                            <div className="col-md-3">
                                <Nav />
                            </div>
                        </div>
                    </div>
                </div>
                {notHome == 'true' && 
                <div className="row">
                    <div className="col-12 no-pad">
                        <Backlink href="/">go back</Backlink>
                    </div>
                </div>
                }
            </Header>
        )
    }
}

export default ShowHeader
