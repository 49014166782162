import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import get from 'lodash/get'
import { Helmet } from "react-helmet"
import { Tween, Timeline, TimelineLite } from 'gsap'
import styled from 'styled-components'
import Header from "../components/header"
import Footer from "../components/footer"
import Img from 'gatsby-image'
import Modal from 'react-modal'

// colors 
let primary = '#a8ee56';

const Subheading = styled.h2`
  font-family: 'Recoleta';
  font-weight: 400;
  margin-bottom: 1.5rem;
  font-size: 2.25rem;
`

const Top = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px){
    height: 100vh;
  }
`

const WorkContainer = styled.div`
  margin: 0 0 2rem;
  div{
    padding: 1rem 0;
  }
  a{
    font-size: 2.5rem;
    color: #000;
    display: block;
    // text-transform: lowercase;
    font-weight: 400;
    &:hover{
      text-decoration: none;
      color: ${primary};
    }
  }
  p{
    text-transform: uppercase;
    font-family: monospace;
  }
`

const PostContainer = styled.div`
margin: 0 0 2rem;
div{
  padding: 1rem 0;
}
a{
  font-size: 2.5rem;
  color: #000;
  display: block;
  font-weight: 400;
  &:hover{
    text-decoration: none;
    color: ${primary};
  }
}
`

const Block = styled.div`
  height: auto;
  width: 300px;
  background: 0 0;
  display: inline-block;
  position: absolute;
  right: 0;
  top: -6rem;
  overflow: hidden;
  z-index: 1;
  img{
    position: relative;
    // left: -100%;
    opacity: 0;
    z-index: 0;
    width: 100%;
  }
  .flow{
    display:block;
    width: 10px;
    height: 10px;
    top: 0;
    background: #fbf8f8;
    position: absolute;
    z-index: 1;
    trnaition: all .45s ease-in-out;
  }
`

const Smallblock = styled.div`
  height: auto;
  width: 300px;
  background: 0 0;
  display: inline-block;
  position: absolute;
  right: 10rem;
  top: 4rem;
  overflow: hidden;
  z-index: 2;
  img{
    position: relative;
    // left: -100%;
    opacity: 0;
    z-index: 0;
    width: 100%;
    filter: brightness(95%);
  }
  .flow{
    display:block;
    width: 10px;
    height: 10px;
    bottom: 0;
    background: #fbf8f8;
    position: absolute;
    z-index: 1;
  }
`

const Minorhead = styled.h3`
  font-family: 'Recoleta';
  font-weight: 900;
  margin: 1rem 0 2.5rem;
`

const Available = styled.div`
  display: inline-block;
  background: #fafafa;
  border: 1px solid #f0eded;
  padding: 1rem 2.5rem;
  border-radius: 250px;
  margin-top: 1rem;
  transition: all .45s ease-in-out;
  .notify{
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: inline-block;
    &.active{
      background-color: #8BC34A;
      animation: pulse 2s infinite;
    }
    &.inactive{
      animation: pulsealt 2s infinite;
      background-color: #ef5350;
    }
  }
  p{
    display: inline-block;
    padding: 0 0 0 1rem;
    color: #969696;
    user-select: none; 
    margin-bottom: 0;
  }
  &:hover{
    box-shadow:0px 5px 5px 0px rgba(0, 0, 0, 0.03);
    cursor: pointer;
  }
`

const Content = styled.div`
 position: relative;
 z-index: 3;
 a{
   font-weight: 600;
   color: #121212;
   &:hover{
    color: ${primary};
    text-decoration: none;
   }
 }
 .img-contact{
   display: block;
   padding: 0;
   height: 0px;
   width: 165px;
   position: relative;
   overflow: hidden;
  //  transition: height, 0.15s ease-in-out;
   &.show{
    height: 130px;
    padding: 2rem 0;
   }
 }
`

const Smalls = styled.p`
 font-size: 12px;
 color: #a7a7a9;
 margin: 30px 10px 0;
`

class Index extends Component {
  alert(){
    var el = document.querySelector(".img-contact");
    el.classList.toggle('show')
  }
  componentDidMount(){
    console.log('start');
    // img animate
    var activityCounter = 0;
      if(activityCounter === 0){
        var tl = new TimelineLite();
        var tla = new TimelineLite();
        tla.pause();

        tl.to('.block .flow', 1, {background: primary, height: '100%'})
        tl.to('.block .flow', 1, {width:'100%', right: 0})
        tl.to('.block img', .1, {opacity: 1})
        tla.play();
        tl.to('.block .flow', 1, {width: 0});

        tla.to('.smallblock .flow', 1, {filter: 'brightness(95%)', background: primary, width: '100%'})
        tla.to('.smallblock .flow', 1, {height: '100%', top: 0})
        tla.to('.smallblock img', .1, {opacity: 1})
        tla.to('.smallblock .flow', 1, {height: 0})
        activityCounter++;
      }
  }
  render(){
    const homepage = get(this, 'props.data.homepage')
    const works = get(this, 'props.data.works')
    const posts = get(this, 'props.data.posts')
    return(
      <div className="container">
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{homepage.data.name.text}</title>
            <link rel="shortcut icon" type="image/png" href="dot.png"/>
            {typeof window !== 'undefined' &&
              <link rel="canonical" href={window.location.href} />
            }
            <meta name="description" content={homepage.data.heading.text} />
          </Helmet>
        </div>
        {/* <Header /> */}
        <Top>
          <div class="col-md-10 mx-auto">
              <div class="row">
                <div class="col-12 col-md-8">
                  {/* Name hidden for now */}
                  {/* <Heading>{homepage.data.name.text}</Heading> */}
                  <Content>
                    <Subheading>👨🏽‍💻</Subheading>
                    <Subheading>{homepage.data.heading.text}</Subheading>
                  </Content>
                </div>
                {/* <div className="col-12 col-md-4">
                    <Block className="block">
                      <img class="img" src={homepage.data.image.url} alt="" />
                      <div class="flow"></div>
                    </Block>
                    <Smallblock className="smallblock">
                      <img class="img" src={homepage.data.image.url} alt="" />
                      <div class="flow"></div>
                    </Smallblock>
                </div> */}
              </div>
              <div class="row">
                <div class="col-12 col-md-7">
                  <Content>
                      <div dangerouslySetInnerHTML={{__html: homepage.data.about.html}} />
                      {homepage.data.available == 'yes' ?
                        <Available onClick={this.alert} >
                          <div className="notify active"></div>
                          <p>Available for work</p>
                        </Available>
                      :
                        <Available onClick={this.alert}>
                          <div className="notify inactive"></div>
                          <p>Not available for work, but drop me an email</p>
                        </Available>
                      }
                      <div className="img-contact">
                        <Img fluid={homepage.data.email_image.localFile.childImageSharp.fluid} />
                      </div>
                      <Smalls>Built with Gatsby, Prismic & Netlify</Smalls>
                  </Content>
                </div>
              </div>
          </div>
        </Top>

        {/* <div className="col-md-10 mx-auto" id="works">
          <WorkContainer>
            <Minorhead>Work.</Minorhead>
            {works.edges.map(work => (
              <div>
                <Link to={work.node.uid}>{work.node.data.title.text}</Link>
                <p>{work.node.data.work_type.text}</p>
              </div>
            ))}
          </WorkContainer>
        </div>
        <div className="col-md-10 mx-auto" id="posts" style={{marginTop: '6rem', marginBottom: '4rem'}}>
          <Minorhead>Writing.</Minorhead>
          <PostContainer>
            {posts.edges.map(post => (
                <div>
                  {post.node.data.external_url !== null ?
                    <a target="_blank" rel="noopener noreferrer" href={post.node.data.external_url.url}>{post.node.data.title.text} <sup>↗</sup> <span></span></a>
                  : 
                    <a href={post.node.uid}>{post.node.data.title.text}</a>
                  }
                </div>
              ))}
          </PostContainer>
        </div> */}
        {/* <form
      name="contact-form"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input name="name" placeholder="Your Name" type="text" />
      <input name="email" placeholder="name@name.com" type="email" />
      <textarea name="message" />
      <button>Send</button>
    </form> */}
          {/* <Footer /> */}
      </div>
    )
  }
}


export default Index


export const pageQuery = graphql`
  query IndexQuery {
    homepage: prismicHome {
      data{
        name{
          text
        }
        available
        email_image{
          url
          localFile{
            childImageSharp{
              fluid(maxWidth: 200, maxHeight: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        heading{
          text
        }
        about{
          html
        }
        about_secondary{
          text
        }
        image{
          url
        }
      }
    }
    works: allPrismicWork {
        edges {
          node {
            uid
            data {
              title {
                text
              }
              work_type{
                text
              }
            }
          }
        }
      }
    posts: allPrismicPost{
      edges{
        node{
          uid
          data{
            title{
              text
            }
            external_url{
              url
            }
          }
        }
      }
    }
  }
`
