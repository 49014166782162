import React, { Component } from "react"
import styled from 'styled-components'

const Foot = styled.div`
  padding: 2rem 0;
  p{
    font-family: monospace;
  }
  .right{
    text-align: right;
    @media screen and (max-width: 768px){
      text-align: left;
    }
  }
`

class Footer extends Component {
  componentDidMount(){
    document.getElementById("year").innerHTML = new Date().getFullYear();
  }
  render(){
    return(
      <Foot>
      <div className="container">
        <div className="col-md-10 mx-auto">
          <div class="row justify-content-between">
            <div class="col-md-5">
              <p>&copy; <span id="year">2018</span>. Hanif Dawjee.</p>
            </div>
            <div class="col-md-5 right">
              <p>Leeds 🇬🇧</p>
            </div>
          </div>
        </div>
      </div>
    </Foot>
    )
  }
}

export default Footer
